import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getVocsChrgList, getVocsStatList } from '../../../../../api/cms/set-boar';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const defaultData = {
    vocId: "",                
    csId: "",                 
    csName: "",                 
    cpId: "",                 
    custId: "",               
    vocGubun: "1:1 문의",            
    vocStatusCd: "",        
    vocKindCd: "",     
    vocKindDetailCd: "",      
    vocTitle: "",             
    vocContents: "",          
    vocActnContents: "",      
    vocCmpltDt: null,         
    imageId1: null,           
    imageId2: null,           
    imageId3: null,           
    imageId4: null,           
    connectorId: null,        
    delYn: "미삭제",               
    checkYn: "미확인"              
};

// 선택 항목
const vocsGubuList = ['1:1 문의', '고장접수'];
const vocsDeleList = ['삭제', '미삭제'];
const vocsChecList = ['확인', '미확인'];

export function BparVocsUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const chrgGridRef = useRef(null);
    const statGridRef = useRef(null);
    const [chrgData, setChrgData] = useState([]);
    const [statData, setStatData] = useState([]);
    const chrgGridUpdate = useCallback((e) => setChrgData(e), []);
    const statGridUpdate = useCallback((e) => setStatData(e), []);
    const chrgKeyExpr = 'id';
    const statKeyExpr = 'station_id';  
    // 그리드 팝업
    const [chrgPopupVisible, setChrgPopupVisible] = useState(false);
    const [statPopupVisible, setStatPopupVisible] = useState(false);
    const chrgPopupHiding = useCallback(() => setChrgPopupVisible(false), []); 
    const statPopupHiding = useCallback(() => setStatPopupVisible(false), []);
    const onChrgPopupClick = useCallback(() => {
        if (data.csId) {
            setChrgPopupVisible(true);
        } else {
            fwUtil.aler.toast.erro('충전소를 먼저 선택 후 시도해주세요!');
        }
    }, [data])

    // 그리드 조회
    const statHandleSearch = useCallback(async () => {
        const sendData = { statIdno: "", statName: "" };
        const res = await getVocsStatList(sendData);
        statGridUpdate(res);
    }, [statGridUpdate]);

    const chrgHandleSearch = useCallback(async () => {
        const sendData = { stationName: data.csName, model: "" };
        const res = await getVocsChrgList(sendData);
        chrgGridUpdate(res);
    }, [data, chrgGridUpdate]);      

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
            if (seleData) {
                const targData = {
                    vocId: seleData.vocId || defaultData.vocId,
                    csId: seleData.csId || defaultData.csId,
                    csName: seleData.csName || defaultData.csName,
                    cpId: seleData.cpId || defaultData.cpId,
                    custId: seleData.custId || defaultData.custId,
                    vocGubun: seleData.vocGubun === "A" ? "1:1 문의" : "고장접수",
                    vocStatusCd: seleData.vocStatusCd || defaultData.vocStatusCd,
                    vocKindCd: seleData.vocKindCd || defaultData.vocKindCd,
                    vocKindDetailCd: seleData.vocKindDetailCd || defaultData.vocKindDetailCd,
                    vocTitle: seleData.vocTitle || defaultData.vocTitle,
                    vocContents: seleData.vocContents || defaultData.vocContents,
                    vocActnContents: seleData.vocActnContents || defaultData.vocActnContents,
                    vocCmpltDt: seleData.vocCmpltDt || defaultData.vocCmpltDt,
                    imageId1: seleData.imageId1 || defaultData.imageId1,
                    imageId2: seleData.imageId2 || defaultData.imageId2,
                    imageId3: seleData.imageId3 || defaultData.imageId3,
                    imageId4: seleData.imageId4 || defaultData.imageId4,
                    connectorId: seleData.connectorId || defaultData.connectorId,
                    delYn: seleData.delYn === "Y" ? "삭제" : "미삭제",
                    checkYn: seleData.checkYn === "Y" ? "확인" : "미확인",                    
                };            
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            console.log(error)
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            vocId: data.vocId || defaultData.vocId,
            csId: data.csId || defaultData.csId,
            cpId: data.cpId || defaultData.cpId,
            custId: data.custId || defaultData.custId,
            vocGubun: data.vocGubun === "1:1 문의" ? "A" : "B",
            vocStatusCd: data.vocStatusCd || defaultData.vocStatusCd,
            vocKindCd: data.vocKindCd || defaultData.vocKindCd,
            vocKindDetailCd: data.vocKindDetailCd || defaultData.vocKindDetailCd,
            vocTitle: data.vocTitle || defaultData.vocTitle,
            vocContents: data.vocContents || defaultData.vocContents,
            vocActnContents: data.vocActnContents || defaultData.vocActnContents,
            vocCmpltDt: data.vocCmpltDt ? fwUtil.date.convTime.fmDate(defaultData.vocCmpltDt) : null,
            imageId1: data.imageId1 || defaultData.imageId1,
            imageId2: data.imageId2 || defaultData.imageId2,
            imageId3: data.imageId3 || defaultData.imageId3,
            imageId4: data.imageId4 || defaultData.imageId4,
            connectorId: data.connectorId || defaultData.connectorId,
            delYn: data.delYn === "미삭제" ? "N" : "Y",
            checkYn: data.checkYn === "미확인" ? "N" : "Y",            
        };

        await fwUtil.apis.check(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    const statPopupOnShowing = useCallback(() => {
        statHandleSearch();
    }, [statHandleSearch]);

    const chrgPopupOnShowing = useCallback(() => {
        chrgHandleSearch();
    }, [chrgHandleSearch]);

    const statOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'csId': e.data.station_id, 'csName': e.data.station_name, 'cpId': '' });
        statPopupHiding();
    }, [data, dataUpdate, statPopupHiding]);

    const chrgOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'cpId': e.data.id });
        chrgPopupHiding();
    }, [data, dataUpdate, chrgPopupHiding]);

    const statGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'충전소'}
                role={'목록'}
                gridDataSource={statData}
                gridRef={statGridRef}
                mainKey={statKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={statOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >                
                {CommCol({ dataField: "station_name", caption: "충전소명" })}
                {CommCol({ dataField: "stat_envi", caption: "환경부ID" })}
            </ScrollGrid>
        )
    }, [statData, statGridRef, statKeyExpr, statOnRowDblClick, isXSmall, isSmall]);

    const chrgGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'충전기'}
                role={'목록'}
                gridDataSource={chrgData}
                gridRef={chrgGridRef}
                mainKey={chrgKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowClick={() => console.log("ok")}
                onRowDblClick={chrgOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "station_name", caption: "충전소명" })}
                {CommCol({ dataField: "id", caption: "충전기ID" })}
                {CommCol({ dataField: "model", caption: "충전기모델" })}             
            </ScrollGrid>
        )
    }, [chrgData, chrgGridRef, chrgKeyExpr, chrgOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'충전기 조회'}
                visible={chrgPopupVisible}
                shown={chrgPopupOnShowing}
                hiding={chrgPopupHiding}
            >
                {chrgGrid}
            </PopupGrid>     

            <PopupGrid
                title={'충전소 조회'}
                visible={statPopupVisible}
                shown={statPopupOnShowing}
                hiding={statPopupHiding}
            >
                {statGrid}
            </PopupGrid>
            <PopupForm
                title={'고장신고 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : isMedium ? 900 : 1240}
            >
                <GroupItem colCount={isXSmall || isSmall || isMedium ? 1 : 2}>
                    <GroupItem colSpan={isXSmall || isSmall || isMedium ? 1 : 2}>
                        {FormText({ value: data.vocTitle, onValueChange: updateField("vocTitle"), label: "문의제목", required: true })}
                        {FormMemo({ value: data.vocContents, onValueChange: updateField("vocContents"), label: "문의내용", required: true })}
                        {FormMemo({ value: data.vocActnContents, onValueChange: updateField("vocActnContents"), label: "답변내용" })}         
                    </GroupItem>           
                    {FormGrid({ value: data.csId, onValueChange: updateField("csId"), label: "충전소ID", required: true, onClick: () => setStatPopupVisible(true) })}
                    {FormText({ value: data.csName, onValueChange: updateField("csName"), label: "충전소명", disabled: true })}
                    {FormGrid({ value: data.cpId, onValueChange: updateField("cpId"), label: "충전기ID", required: true, onClick: () => onChrgPopupClick() })}
                    {FormSelc({ value: data.connectorId, onValueChange: updateField("connectorId"), label: "커넥터ID", items: [1, 2] })}
                    {FormRegx({ value: data.custId, onValueChange: updateField("custId"), label: "고객ID", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자'  })}
                    {FormSelc({ value: data.vocGubun, onValueChange: updateField("vocGubun"), label: "구분", items: vocsGubuList })}
                    {FormRegx({ value: data.vocStatusCd, onValueChange: updateField("vocStatusCd"), label: "접수/조치 상태", rule: /^[a-zA-Z가-힣0-9\s]{1,2}$/, placeholder: '영문, 한글, 숫자 2자' })}
                    {FormDate({ value: data.vocCmpltDt, onValueChange: updateField("vocCmpltDt"), label: "접수처리 완료일" })}                    
                    {FormRegx({ value: data.vocKindCd, onValueChange: updateField("vocKindCd"), label: "민원종류", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자' })}
                    {FormRegx({ value: data.vocKindDetailCd, onValueChange: updateField("vocKindDetailCd"), label: "민원상세종류", rule: /^[a-zA-Z가-힣0-9\s]{1,100}$/, placeholder: '영문, 한글, 숫자 100자' })}
                    {FormRegx({ value: data.imageId1, onValueChange: updateField("imageId1"), label: "이미지ID1", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자' })}
                    {FormRegx({ value: data.imageId2, onValueChange: updateField("imageId2"), label: "이미지ID2", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자' })}
                    {FormRegx({ value: data.imageId3, onValueChange: updateField("imageId3"), label: "이미지ID3", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자' })}
                    {FormRegx({ value: data.imageId4, onValueChange: updateField("imageId4"), label: "이미지ID4", rule: /^[a-zA-Z가-힣0-9\s]{1,20}$/, placeholder: '영문, 한글, 숫자 20자' })}
                    {FormSelc({ value: data.delYn, onValueChange: updateField("delYn"), label: "삭제여부", items: vocsDeleList })}
                    {FormSelc({ value: data.checkYn, onValueChange: updateField("checkYn"), label: "확인여부", items: vocsChecList })}
                </GroupItem>
            </PopupForm>
        </>
    );
};