import React from "react";
import { Button, Column } from "devextreme-react/data-grid";
import { gridUtil } from "./grid-column-util";
import { fwUtil } from "../../../util";

const CountCol = (props) => {
  const { maxLength, groupIndex, key } = props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      width={60}
      minWidth={60}
      alignment={gridUtil.defaOpts.alig}
      cellRender={(cell) => gridUtil.render.coun(cell)}
      groupIndex={groupIndex}
      editorOptions={{
        maxLength: maxLength,
      }}
    />
  );
};

const CustCol = (props) => {
  const {
    maxLength,
    header,
    groupIndex,
    groupRender,
    displayExpr,
    cellRender,
    key
  } = props;


  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      headerCellRender={header}
      groupCellRender={groupRender}
      groupIndex={groupIndex}
      calculateDisplayValue={displayExpr}
      alignment={gridUtil.defaOpts.alig}
      cellRender={cellRender}
      editorOptions={{
        maxLength: maxLength,
      }}
    />
  );
};


const CommCol = (props) => {
  const {
    width,
    maxLength,
    header,
    groupIndex,
    groupRender,
    displayExpr,
    key
  } = props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      width={width}
      headerCellRender={header}
      groupCellRender={groupRender}
      groupIndex={groupIndex}
      calculateDisplayValue={displayExpr}
      alignment={gridUtil.defaOpts.alig}
      editorOptions={{
        maxLength: maxLength,
      }}
    />
  );
};

const StatCol = (props) => {
  const {
    width,
    groupIndex,
    warning,
    info,
    danger,
    customCell,
    maxLength,
    key
  } = props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      width={width}
      groupIndex={groupIndex}
      alignment={gridUtil.defaOpts.alig}
      editorOptions={{
        maxLength: maxLength,
      }}
      cellRender={(cellInfo) =>
        customCell
          ? customCell(cellInfo)
          : gridUtil.render.stat(cellInfo, warning, info, danger)
      }
    />
  );
};

const TimsCol = (props) => {
  const {
    dataField,
    width,
    maxLength,
    header,
    groupIndex,
    groupRender,
    key
  } = props;

  function convertSecondsToHMS(data, field) {
    let value = data && data[field];

    if (value === undefined || value === null) {
        return "00:00:00";
    }

    value = fwUtil.date.convTime.toTime(value);
    return value;
}

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      width={width}
      headerCellRender={header}
      groupCellRender={groupRender}
      groupIndex={groupIndex}
      calculateCellValue={(e) => convertSecondsToHMS(e, dataField)}
      alignment={gridUtil.defaOpts.alig}
      editorOptions={{
        maxLength: maxLength,
      }}
    />
  );
};


const TimeCol = (props) => {
  const { width, maxLength, key } = props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"date"}
      alignment={gridUtil.defaOpts.alig}
      format={"HH:mm:ss"}
      width={width}
      editorOptions={{
        max: Math.pow(10, maxLength - 1),
        min: 0,
      }}
    />
  );
};

const MontCol = (props) => {
  const { width, key } = props;

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      width={width || widthValue}
      minWidth={width || widthValue}
      alignment={gridUtil.defaOpts.alig}
      dataType={"date"}
      format={"yyyy-MM"}
    />
  );
};

const DateCol = (props) => {
  const { width, key } = props;

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      width={width || widthValue}
      minWidth={width || widthValue}
      alignment={gridUtil.defaOpts.alig}
      dataType={"date"}
      format={"yyyy-MM-dd"}
    />
  );
};

const DtmeCol = (props) => {
  const { width, maxLength, key } = props;

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      width={width || widthValue}
      minWidth={width || widthValue}
      alignment={gridUtil.defaOpts.alig}
      dataType={"date"}
      format={"yyyy-MM-dd HH:mm:ss.SSS"}
      editorOptions={{
        max: Math.pow(10, maxLength - 1),
        min: 0,
      }}
    />
  );
};

const NumbCol = (props) => {
  const { dataField, width, maxLength, suffix, alignment, key } = props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      width={width}
      dataType={"number"}
      alignment={alignment || "right"}
      calculateDisplayValue={(data) =>
        gridUtil.calculate.numb(data, dataField, suffix)
      }
      editorOptions={{
        maxLength: maxLength,
      }}
    />
  );
};

const DeciCol = (props) => {
  const { alignment, dataField, width, suffix, customCellValue, maxLength, key } =
    props;

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      width={width}
      dataType={"number"}
      alignment={alignment || "right"}
      calculateDisplayValue={(data) =>
        gridUtil.calculate.deci(data, dataField, suffix, customCellValue)
      }
      editorOptions={{
        max: Math.pow(10, maxLength - 1),
        min: 0,
      }}
    />
  );
};

const BoolCol = (props) => {
  const { dataField, width, falseText, trueText, headerCellRender, key } = props;

  const widthValue = fwUtil.conv.tern(140, width);
  const falseTextValue = fwUtil.conv.tern("false", falseText);
  const trueTextValue = fwUtil.conv.tern("true", trueText);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"boolean"}
      alignment={"center"}
      width={widthValue}
      falseText={falseTextValue}
      trueText={trueTextValue}
      headerCellRender={headerCellRender}
      calculateCellValue={(data) => gridUtil.calculate.bool(data, dataField)}
    />
  );
};

const SelcCol = (props) => {
  const { width, minWidth, placeholder, items, key } = props;

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      dataType={"string"}
      width={width || widthValue}
      minWidth={minWidth || widthValue}
      alignment={gridUtil.defaOpts.alig}
      editCellRender={(cell) => gridUtil.render.selc(cell, items, placeholder)}
    />
  );
};

const BtnsCol = (props) => {
  const { width, allowDelete, button, key } = props;
  if (!button) {
    return null;
  }

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      type="buttons"
      width={widthValue}
      minWidth={widthValue}
    >
      {allowDelete && <Button name="delete" />}
      <Button hint={button.hint} icon={button.icon} onClick={button.onClick} />
    </Column>
  );
};

const BarsCol = (props) => {
  const { dataField, name, width, key } = props;

  const widthValue = fwUtil.conv.tern(120, width);

  return (
    <Column
      {...gridUtil.publOpts(props)}
      key={key}
      alignment="left"
      cssClass="sales-bullet"
      name={name}
      width={widthValue}
      cellRender={(cell) => {
        gridUtil.render.bars(cell, dataField);
      }}
    />
  );
};

export {
  CountCol,
  CommCol,
  StatCol,
  TimsCol,
  TimeCol,
  MontCol,
  DateCol,
  DtmeCol,
  NumbCol,
  DeciCol,
  BoolCol,
  SelcCol,
  BtnsCol,
  BarsCol,
  CustCol,
};
