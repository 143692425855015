function transRptsData(dataSet, daysAgo) {
    const filledData = [];
    const today = new Date();
    const dateMap = new Map();

    dataSet.forEach(item => {
        dateMap.set(item.date_time, item);
    });

    for (let i = daysAgo; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i);
        const dateString = date.toISOString().slice(0, 10);

        if (dateMap.has(dateString)) {
            filledData.push(dateMap.get(dateString));
        } else {
            filledData.push({
                date_time: dateString,
                usePower: 0,
                resultPrice: 0,
                usageCount: 0,
                station_id: "",
                station_name: "",
                chargePointId: "",
                connectorId: null,
                chrg_stat: "미사용",
            });
        }
    }

    return filledData;
};

export const moniDashFunc = {
    transRptsData: transRptsData,
};