import { fwUtil } from "../../focuswin/script/util";
import routerInfo from "../../utils/router-info";

const getAserStatList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/qci/aslist/regist/station/list`;
        const data = await fwUtil.apis.search(api, sendData);

        return data;
    }
    catch (error) {
        console.log('getAserStatList.error === ', error);
        return [];
    }
};

const getAserChrgList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/qci/aslist/regist/charger/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getAserChrgList.error === ', error);
        return [];
    }
};

export {
    getAserStatList,
    getAserChrgList,
}