// ChrgStatMana - 충전관리 - 충전소 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, NumbCol, StatCol, TimeCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { ChrgStatInsert } from './InfoMana-insert';
import { ChrgStatUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const enabStatCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === 1 ? '운영중' : '미운영';
    const color = value === 1 ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

const pwrsFuncCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === 1 ? '지중인입' : '가공인입';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`}>{texts}</span>
        </div>
    )
};

const pwrsTypeCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === 1 ? '고압' : '저압';
    const color = value === 1 ? '#ff7777' : '#56a0eb';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

export default function ChrgStatMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'station/register';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'station_id';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [stationName, setStationName] = useState("");
    const [stationAddr, setStationAddr] = useState("");
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);
    const stationAddrChange = useCallback((e) => { setStationAddr(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { stationName: stationName, stationAddr: stationAddr };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [stationName, stationAddr, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '충전소명', value: stationName, valueChange: stationNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '주소', value: stationAddr, valueChange: stationAddrChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stationName, stationNameChange, stationAddr, stationAddrChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-chrg'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'충전소 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명" })}
                    {CommCol({ dataField: "station_address", caption: "충전소주소" })}
                    {CommCol({ dataField: "stat_addr", caption: "상세주소" })}
                    {DateCol({ dataField: "install_date", caption: "설치일" })}
                    {CommCol({ dataField: "modl_numb", caption: "제조번호" })}
                    {CommCol({ dataField: "stat_envi", caption: "환경부용 충전소ID" })}
                    {StatCol({ dataField: "stat_enab", caption: "운영상태", customCell: enabStatCell })}
                    {TimeCol({ dataField: "stat_star", caption: "영업시작" })}
                    {TimeCol({ dataField: "stat_ends", caption: "영업종료" })}
                    {CommCol({ dataField: "stat_viw1", caption: "시설 구분(대)" })}
                    {CommCol({ dataField: "stat_viw2", caption: "시설 구분(소)" })}
                    {DateCol({ dataField: "stat_date", caption: "운영시작일" })}
                    {StatCol({ dataField: "pwrs_func", caption: "수전방식", customCell: pwrsFuncCell })}
                    {CommCol({ dataField: "pwrs_size", caption: "수전용량" })}
                    {StatCol({ dataField: "pwrs_type", caption: "전압종류", customCell: pwrsTypeCell })}
                    {CommCol({ dataField: "pwrs_valu", caption: "계약전력" })}
                    {NumbCol({ dataField: "park_kwon", caption: "주차료" })}
                    {NumbCol({ dataField: "park_cont", caption: "주차면 수" })}
                    {BoolCol({ dataField: "park_yesn", caption: "주차료 유무" })}
                    {CommCol({ dataField: "clie_name", caption: "사업자명" })}
                </PagingGrid>
                <ChrgStatInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <ChrgStatUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
            </div>
        </>
    );
};