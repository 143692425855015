import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { getNotiCompList } from '../../../../../api/cms/set-chrg';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const defaultData = {
    id: 0,
    serviceCompanyId: null,
    subject: "",
    content: "",
    atchFileId: null,
    views: 0,
    regId: null,
    regDate: fwUtil.date.getsText.date(),
    updateDate: null,
    delYn: "미삭제",
    eventPop: "비활성화",
    startEventDate: null,
    endEventDate: null,
    fixYn: "비고정",
    typeCode: "N",
    fixOrder: null,
    sucessYn: "미전송",
};

// 선택 항목
const notiDeleList = ['삭제', '미삭제'];
const notiEvenList = ['활성화', '비활성화'];
const notiFixsList = ['고정', '비고정'];
const notiTypeList = ['Y', 'N'];
const notiSuccList = ['전송', '미전송'];

export function ChrgNotiInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const compGridRef = useRef(null);
    const [compData, setBusiData] = useState([]);
    const compGridUpdate = useCallback((e) => setBusiData(e), []);
    const compKeyExpr = 'id';
    // 그리드 팝업
    const [compPopupVisible, setCompPopupVisible] = useState(false);
    const compPopupHiding = useCallback(() => setCompPopupVisible(false), []);
    // 그리드 조회
    const compHandleSearch = useCallback(async () => {
        const sendData = { };
        const res = await getNotiCompList(sendData);
        compGridUpdate(res);
    }, [compGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        if (field === "eventPop") {
            dataUpdate({ ...data, [field]: value, startEventDate: null, endEventDate: null });
        }

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            id: data.id || 0,
            serviceCompanyId: data.serviceCompanyId || 0,
            subject: data.subject || "",
            content: data.content || "",
            atchFileId: data.atchFileId || null,
            views: data.views || 0,
            updateDate: data.updateDate || null,
            delYn: data.delYn === "미삭제" ? "N" : "Y",
            eventPop: data.eventPop === "비활성화" ? "N" : "Y",
            startEventDate: fwUtil.date.convTime.fmYymd(data.startEventDate),
            endEventDate: fwUtil.date.convTime.fmYymd(data.endEventDate),
            fixYn: data.fixYn === "비고정" ? "N" : "Y",
            typeCode: data.typeCode || "N",
            fixOrder: data.fixOrder || null,
            sucessYn: data.sucessYn === "미전송" ? "N" : "Y",
        };

        await fwUtil.apis.check(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    const compPopupOnShowing = useCallback(() => {
        compHandleSearch();
    }, [compHandleSearch]);

    const compOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'serviceCompanyId': e.data.id, 'companyName': e.data.name });
        compPopupHiding();
    }, [data, dataUpdate, compPopupHiding]);

    const compGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'사업자'}
                role={'목록'}
                gridDataSource={compData}
                gridRef={compGridRef}
                mainKey={compKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={compOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "name", caption: "서비스 사업자명" })}
                {CommCol({ dataField: "bizNum", caption: "사업자 등록번호" })}
                {CommCol({ dataField: "ceoName", caption: "대표자명" })}
            </ScrollGrid>
        )
    }, [compData, compGridRef, compKeyExpr, compOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'서비스 회사명 조회'}
                visible={compPopupVisible}
                shown={compPopupOnShowing}
                hiding={compPopupHiding}
            >
                {compGrid}
            </PopupGrid>
            <PopupForm
                title={'공지사항 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : isMedium ? 900 : 1240}
            >
                <GroupItem colCount={isXSmall || isSmall || isMedium ? 1 : 2}>
                    {FormGrid({ value: data.serviceCompanyId, onValueChange: updateField('serviceCompanyId'), label: '서비스회사 ID', required: true, onClick: () => setCompPopupVisible(true) })}
                    {FormText({ value: data.companyName, onValueChange: updateField('companyName'), label: '서비스회사명', required: true, disabled: true })}
                    <GroupItem colSpan={isXSmall || isSmall || isMedium ? 1 : 2}>
                        {FormText({ value: data.subject, onValueChange: updateField('subject'), label: '제목', required: true })}
                        {FormMemo({ value: data.content, onValueChange: updateField('content'), label: '내용', required: true })}
                    </GroupItem>
                    {FormNumb({ value: data.atchFileId, onValueChange: updateField('atchFileId'), label: '첨부파일 ID' })}
                    {FormNumb({ value: data.views, onValueChange: updateField('views'), label: '조회수', disabled: true })}
                    {FormSelc({ value: data.delYn, onValueChange: updateField('delYn'), label: '삭제여부', items: notiDeleList })}
                    {FormSelc({ value: data.eventPop, onValueChange: updateField('eventPop'), label: '이벤트', items: notiEvenList })}
                    {FormDate({ value: data.startEventDate, onValueChange: updateField('startEventDate'), label: '이벤트 시작일', disabled: data.eventPop === "비활성화" })}
                    {FormDate({ value: data.endEventDate, onValueChange: updateField('endEventDate'), label: '이벤트 종료일', disabled: data.eventPop === "비활성화"  })}
                    {FormSelc({ value: data.fixYn, onValueChange: updateField('fixYn'), label: '고정여부', items: notiFixsList })}
                    {FormNumb({ value: data.fixOrder, onValueChange: updateField('fixOrder'), label: '고정순서', disabled: data.fixYn === "비고정", max: 999, required: data.fixYn === "고정" })}
                    {FormSelc({ value: data.typeCode, onValueChange: updateField('typeCode'), label: '유형코드', items: notiTypeList })}
                    {FormSelc({ value: data.sucessYn, onValueChange: updateField('sucessYn'), label: '전송여부', items: notiSuccList })}
                </GroupItem>
            </PopupForm>
        </>
    );
};