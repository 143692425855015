import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormNumb, FormRadi } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { getPricChrgList } from '../../../../../api/cms/set-chrg';

const defaultData = {
    charge_id: '',
    apply_date: fwUtil.date.getsText.date(),
    apply_yn: 'N',
    h00: 0,
    h01: 0,
    h02: 0,
    h03: 0,
    h04: 0,
    h05: 0,
    h06: 0,
    h07: 0,
    h08: 0,
    h09: 0,
    h10: 0,
    h11: 0,
    h12: 0,
    h13: 0,
    h14: 0,
    h15: 0,
    h16: 0,
    h17: 0,
    h18: 0,
    h19: 0,
    h20: 0,
    h21: 0,
    h22: 0,
    h23: 0,
};

const pricApplList = [{ idno: 'Y', text: '회원' },{ idno: 'N', text: '비회원' }];

export function ChrgPricInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const chrgGridRef = useRef(null);
    const [chrgData, setChrgData] = useState([]);
    const chrgGridUpdate = useCallback((e) => setChrgData(e), []);
    const chrgKeyExpr = 'id';
    // 그리드 팝업
    const [chrgPopupVisible, setChrgPopupVisible] = useState(false);
    const chrgPopupHiding = useCallback(() => setChrgPopupVisible(false), []);      

    // 그리드 조회
    const chrgHandleSearch = useCallback(async () => {
        const sendData = { station_name: "", model: "" };
        const res = await getPricChrgList(sendData);
        chrgGridUpdate(res);
    }, [chrgGridUpdate]);      

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            chargeId: data.charge_id || '',
            applyDate: data.apply_date ? fwUtil.date.convTime.fmDate(data.apply_date) : fwUtil.date.getsText.date(),
            applyYn: data.apply_yn,
            h00: data.h00 || 0,
            h01: data.h01 || 0,
            h02: data.h02 || 0,
            h03: data.h03 || 0,
            h04: data.h04 || 0,
            h05: data.h05 || 0,
            h06: data.h06 || 0,
            h07: data.h07 || 0,
            h08: data.h08 || 0,
            h09: data.h09 || 0,
            h10: data.h10 || 0,
            h11: data.h11 || 0,
            h12: data.h12 || 0,
            h13: data.h13 || 0,
            h14: data.h14 || 0,
            h15: data.h15 || 0,
            h16: data.h16 || 0,
            h17: data.h17 || 0,
            h18: data.h18 || 0,
            h19: data.h19 || 0,
            h20: data.h20 || 0,
            h21: data.h21 || 0,
            h22: data.h22 || 0,
            h23: data.h23 || 0,
        };

        await fwUtil.apis.check(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    const chrgPopupOnShowing = useCallback(() => {
        chrgHandleSearch();
    }, [chrgHandleSearch]);

    const chrgOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'charge_id': e.data.id });
        chrgPopupHiding();
    }, [data, dataUpdate, chrgPopupHiding]);

    const chrgGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'충전기'}
                role={'목록'}
                gridDataSource={chrgData}
                gridRef={chrgGridRef}
                mainKey={chrgKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={chrgOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "station_name", caption: "충전소명" })}
                {CommCol({ dataField: "id", caption: "충전기ID" })}
                {CommCol({ dataField: "model", caption: "충전기모델" })}             
            </ScrollGrid>
        )
    }, [chrgData, chrgGridRef, chrgKeyExpr, chrgOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'충전기 조회'}
                visible={chrgPopupVisible}
                shown={chrgPopupOnShowing}
                hiding={chrgPopupHiding}
            >
                {chrgGrid}
            </PopupGrid>        
            <PopupForm
                title={'충전단가 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 800}
            >
                <GroupItem>
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} colSpan={isXSmall || isSmall ? 1 : 2}>
                        {FormGrid({ value: data.charge_id, onValueChange: updateField('charge_id'), label: '충전기 ID', onClick: () => setChrgPopupVisible(true) })}
                        {FormDate({ value: data.apply_date, onValueChange: updateField('apply_date'), label: '적용일자' })}
                        {FormRadi({ value: data.apply_yn, onValueChange: updateField('apply_yn'), label: '회원구분', items: pricApplList, valueExpr: 'idno', displayExpr: 'text' })}
                    </GroupItem>
                    <GroupItem colCount={isXSmall || isSmall ? 1 : 2} cssClass='fw-form-group chrg-pric-pric'>
                        <GroupItem>
                            {FormNumb({ value: data.h00, onValueChange: updateField('h00'), label: '00시', length: [10, 0] })}
                            {FormNumb({ value: data.h01, onValueChange: updateField('h01'), label: '01시', length: [10, 0] })}
                            {FormNumb({ value: data.h02, onValueChange: updateField('h02'), label: '02시', length: [10, 0] })}
                            {FormNumb({ value: data.h03, onValueChange: updateField('h03'), label: '03시', length: [10, 0] })}
                            {FormNumb({ value: data.h04, onValueChange: updateField('h04'), label: '04시', length: [10, 0] })}
                            {FormNumb({ value: data.h05, onValueChange: updateField('h05'), label: '05시', length: [10, 0] })}
                            {FormNumb({ value: data.h06, onValueChange: updateField('h06'), label: '06시', length: [10, 0] })}
                            {FormNumb({ value: data.h07, onValueChange: updateField('h07'), label: '07시', length: [10, 0] })}
                            {FormNumb({ value: data.h08, onValueChange: updateField('h08'), label: '08시', length: [10, 0] })}
                            {FormNumb({ value: data.h09, onValueChange: updateField('h09'), label: '09시', length: [10, 0] })}
                            {FormNumb({ value: data.h10, onValueChange: updateField('h10'), label: '10시', length: [10, 0] })}
                            {FormNumb({ value: data.h11, onValueChange: updateField('h11'), label: '11시', length: [10, 0] })}
                        </GroupItem>
                        <GroupItem>
                            {FormNumb({ value: data.h12, onValueChange: updateField('h12'), label: '12시', length: [10, 0] })}
                            {FormNumb({ value: data.h13, onValueChange: updateField('h13'), label: '13시', length: [10, 0] })}
                            {FormNumb({ value: data.h14, onValueChange: updateField('h14'), label: '14시', length: [10, 0] })}
                            {FormNumb({ value: data.h15, onValueChange: updateField('h15'), label: '15시', length: [10, 0] })}
                            {FormNumb({ value: data.h16, onValueChange: updateField('h16'), label: '16시', length: [10, 0] })}
                            {FormNumb({ value: data.h17, onValueChange: updateField('h17'), label: '17시', length: [10, 0] })}
                            {FormNumb({ value: data.h18, onValueChange: updateField('h18'), label: '18시', length: [10, 0] })}
                            {FormNumb({ value: data.h19, onValueChange: updateField('h19'), label: '19시', length: [10, 0] })}
                            {FormNumb({ value: data.h20, onValueChange: updateField('h20'), label: '20시', length: [10, 0] })}
                            {FormNumb({ value: data.h21, onValueChange: updateField('h21'), label: '21시', length: [10, 0] })}
                            {FormNumb({ value: data.h22, onValueChange: updateField('h22'), label: '22시', length: [10, 0] })}
                            {FormNumb({ value: data.h23, onValueChange: updateField('h23'), label: '23시', length: [10, 0] })}
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
            </PopupForm>
        </>
    );
}