import React, { memo } from 'react';
import { CommChart } from '../../../../script/components/charts/charts';
import { BarsSeri, CommAxis, SpinSeri } from '../../../../script/components/charts/charts-item';

const MoniDashRptsChar = memo((props) => {
    const {
        charData, charRef, charHeight,
        additonTitle
    } = props;

    const kwhColor = ["#2c8ef8", "#39afd1"]; // 충전량(kWh) 시리즈 색상
    const payColor = ["#fd7e14", "#ffbc00"]; // 매출액(원) 시리즈 색상
    const height = charHeight;
    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.seriesName}: ${arg.valueText}`,
        };
    };

    return (
        <CommChart
            additonTitle={additonTitle}
            height={height}
            charRef={charRef}
            chartsDataSource={charData}
            legend={{ vertical: "top", horizontal: "center" }}
            tooltip={{ location: "edge", template: tooltipTamplate }}
        >
            {CommAxis({ name: "ChargeKW", caption: "충전량(kWh)", position: "left", tick: 20, start: 0 })}
            {CommAxis({ name: "ChargePay", caption: "매출액(원)", position: "right", tick: 2000, start: 0 })}
            {BarsSeri({ axis: "ChargeKW", valueField: "usePower", caption: "충전량", color: kwhColor[0], args: "date_time" })}
            {SpinSeri({ axis: "ChargePay", valueField: "resultPrice", caption: "매출액", color: payColor[0], args: "date_time" })}
        </CommChart>
    );
});

export default MoniDashRptsChar;
