// ChrgNotiMana - 충전관리 - 공지사항(CP) 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { ChrgNotiInsert } from './InfoMana-insert';
import { ChrgNotiUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';
import { PopupWarn } from '../../../../script/components/popup/popup';

const statDeleCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "N" ? '미삭제' : '삭제';
    const color = value === "N" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

const statEvenCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "Y" ? '활성화' : '비활성화';
    const color = value === "Y" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

const statFixsCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "Y" ? '고정' : '비고정';
    const color = value === "Y" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

const statSuccCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "Y" ? '전송' : '미전송';
    const color = value === "Y" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};


export default function ChrgNotiMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'notice/register';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    const infoSendApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'send');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'id';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [companyName, setCompanyName] = useState("");
    const [subject, setSubject] = useState("");
    const companyNameChange = useCallback((e) => { setCompanyName(e) }, []);
    const subjectChange = useCallback((e) => { setSubject(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);
    // 전송 팝업창
    const [sendPopupVisible, setSendPopupVisible] = useState(false);
    const sendPopupHide = useCallback(() => setSendPopupVisible(false), []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { companyName: companyName, subject: subject };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef);
    }, [companyName, subject, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }

            if (act === 'send') {
                setSendPopupVisible(true);
                return;
            }

        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    const sendPopupConfirm = useCallback(() => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            const sendData = { id: selectedRowKey }
            fwUtil.apis.submit(infoSendApi,sendData,handleSearch)
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        }
    }, [infoSendApi, handleSearch])

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '서비스 회사명', value: companyName, valueChange: companyNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '제목', value: subject, valueChange: subjectChange, search: handleSearch },
        { type: 'btns', locate: 'after', icon: 'message', text: '전송', disabled: authList.auth_upda === 0, onClick: () => handleActionClick('send') },        
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [companyName, companyNameChange, subject, subjectChange, handleSearch, authList, handleActionClick]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-chrg'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'공지사항 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "companyName", caption: "서비스 회사명" })}
                    {CommCol({ dataField: "subject", caption: "제목" })}
                    {CommCol({ dataField: "content", caption: "내용" })}
                    {CommCol({ dataField: "atchFileId", caption: "첨부파일 ID" })}
                    {NumbCol({ dataField: "views", caption: "조회수" })}
                    {StatCol({ dataField: "delYn", caption: "삭제여부", customCell: statDeleCell })}
                    {StatCol({ dataField: "eventPop", caption: "이벤트여부", customCell: statEvenCell })}
                    {CommCol({ dataField: "startEventDate", caption: "이벤트 시작일" })}
                    {CommCol({ dataField: "endEventDate", caption: "이벤트 종료일" })}
                    {CommCol({ dataField: "typeCode", caption: "유형코드" })}
                    {StatCol({ dataField: "fixYn", caption: "고정여부", customCell: statFixsCell })}
                    {NumbCol({ dataField: "fixOrder", caption: "고정순서" })}
                    {StatCol({ dataField: "sucessYn", caption: "전송 성공여부", customCell: statSuccCell })}
                    {CommCol({ dataField: "user_name", caption: "작성자명" })}
                    {DateCol({ dataField: "regDate", caption: "작성일" })}
                    {DateCol({ dataField: "updateDate", caption: "수정일" })}
                </PagingGrid>
                <ChrgNotiInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <ChrgNotiUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
                <PopupWarn
                    visible={sendPopupVisible}
                    hiding={sendPopupHide}
                    message={'선택된 공지사항을 전송하시겠습니까?'}
                    confirm={sendPopupConfirm}
                />                
            </div>
        </>
    );
};