import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUser, adminSignIn, adminSignOut, userSignIn, userSignOut } from '../api/auth';
import { fwUtil } from '../focuswin/script/util';

function AuthProvider(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userMenu, setMenu] = useState([]);

  // 초기 메뉴 조회
  useEffect(() => {
    (async function () {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
        setMenu(userResult.userMenu);
      } else {
        setUser(null);
        setMenu(null);
      }
      setLoading(false);
    })();
  }, []);

  const getMenu = useCallback(() => userMenu, [userMenu]);

  const authCheck = async (setLoading, setAuthList) => {
    const currentMenu = userMenu.find(menu => menu.menu_path === location.pathname);

    if (currentMenu && currentMenu.auth_acce) {
      const currentAuth = {
        auth_acce: currentMenu.auth_acce || 0,
        auth_dele: currentMenu.auth_dele || 0,
        auth_dnlo: currentMenu.auth_dnlo || 0,
        auth_inse: currentMenu.auth_inse || 0,
        auth_sele: currentMenu.auth_sele || 0,
        auth_upda: currentMenu.auth_upda || 0,
        auth_uplo: currentMenu.auth_uplo || 0,     
      }
      setAuthList && setAuthList(currentAuth);
      setLoading(false);
    } else {
      navigate('/home');
      fwUtil.aler.toast.erro('잘못된 접근입니다');
      return;
    }
  };  

  // 로그인
  const signInAdmin = useCallback(async (userIdno, passWord) => {
    const result = await adminSignIn(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      setMenu(result.userMenu);
      fwUtil.aler.toast.info(`${result.data.userName}님 반갑습니다.`);
    }
    return result;
  }, []);

  const signInUser = useCallback(async (userIdno, passWord) => {
    const result = await userSignIn(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      fwUtil.aler.toast.info(`${result.data.userIdno}님 반갑습니다.`);
    }
    return result;
  }, []);

  // 로그아웃
  const signOutAdmin = useCallback(async () => {
    const result = await adminSignOut();
    if (result.isOk) {
      setUser(undefined);
    }
  }, []);

  const signOutUser = useCallback(async () => {
    const result = await userSignOut();
    if (result.isOk) {
      setUser(undefined);
      window.location.reload();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signInAdmin, signInUser, signOutAdmin, signOutUser, loading, setLoading, getMenu, authCheck }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }

