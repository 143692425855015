import BoarVocsMana from "./vocs/InfoMana-page";

export const boar = ({ acce, code }) => {
    const cateEle = 'bor';

    const comp = {
        vocsMana: <BoarVocsMana acce={acce} cate={cateEle} code={`${code}4`} mainTitle={'고장신고'} mainRole={'관리'} />,
    }

    return comp;
}