import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { getAserChrgList, getAserStatList } from '../../../../../api/cms/set-intr';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const defaultData = {
    aser_numb: '',
    aser_gubu: '접수',
    stin_idno: '',
    stin_name: '',
    cpin_idno: '',
    cpin_modl: '',
    clie_name: '',
    clie_teln: '',
    acce_date: fwUtil.date.getsText.date(),
    acce_name: '',
    acce_memo: '',
    comp_name: '',
    comp_date: fwUtil.date.getsText.date(),
    comp_memo: '',
    aser_memo: '',
};

const gubuItem = ['접수', '안내', '예정', '진행중', '완료'];

export function InfrAserInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 팝업 그리드
    const chrgGridRef = useRef(null);
    const statGridRef = useRef(null);
    const [chrgData, setChrgData] = useState([]);
    const [statData, setStatData] = useState([]);
    const chrgGridUpdate = useCallback((e) => setChrgData(e), []);
    const statGridUpdate = useCallback((e) => setStatData(e), []);
    const chrgKeyExpr = 'id';
    const statKeyExpr = 'station_id';    
    // 그리드 팝업
    const [chrgPopupVisible, setChrgPopupVisible] = useState(false);
    const [statPopupVisible, setStatPopupVisible] = useState(false);
    const chrgPopupHiding = useCallback(() => setChrgPopupVisible(false), []); 
    const statPopupHiding = useCallback(() => setStatPopupVisible(false), []);
    const onChrgPopupClick = useCallback(() => {
        if (data.stin_idno) {
            setChrgPopupVisible(true);
        } else {
            fwUtil.aler.toast.erro('충전소를 먼저 선택 후 시도해주세요!');
        }
    }, [data])

    // 그리드 조회
    const statHandleSearch = useCallback(async () => {
        const sendData = { statIdno: "", statName: "" };
        const res = await getAserStatList(sendData);
        statGridUpdate(res);
    }, [statGridUpdate]);

    const chrgHandleSearch = useCallback(async () => {
        const sendData = { stationName: data.stin_name, model: "" };
        const res = await getAserChrgList(sendData);
        chrgGridUpdate(res);
    }, [data, chrgGridUpdate]);      

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            aserNumb: data.aser_numb || '',
            aserGubu: data.aser_gubu || '',
            stinIdno: data.stin_idno || '',
            stinName: data.stin_name || '',
            cpinIdno: data.cpin_idno || '',
            cpinModl: data.cpin_modl || '',
            clieName: data.clie_name || '',
            clieTeln: data.clie_teln || '',
            acceDate: data.acce_date || fwUtil.date.getsText(),
            acceName: data.acce_name || '',
            acceMemo: data.acce_memo || '',
            compName: data.comp_name || '',
            compDate: data.comp_date || fwUtil.date.getsText(),
            compMemo: data.comp_memo || '',
            aserMemo: data.aser_memo || '',
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, refresh, hiding]);

    const statPopupOnShowing = useCallback(() => {
        statHandleSearch();
    }, [statHandleSearch]);

    const chrgPopupOnShowing = useCallback(() => {
        chrgHandleSearch();
    }, [chrgHandleSearch]);

    const statOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'stin_idno': e.data.station_id, 'stin_name': e.data.station_name, 'cpin_idno': '', 'cpin_modl': '' });
        statPopupHiding();
    }, [data, dataUpdate, statPopupHiding]);

    const chrgOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'cpin_idno': e.data.id, 'cpin_modl': e.data.model });
        chrgPopupHiding();
    }, [data, dataUpdate, chrgPopupHiding]);

    const statGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'충전소'}
                role={'목록'}
                gridDataSource={statData}
                gridRef={statGridRef}
                mainKey={statKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={statOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >                
                {CommCol({ dataField: "station_name", caption: "충전소명" })}
                {CommCol({ dataField: "stat_envi", caption: "환경부ID" })}
            </ScrollGrid>
        )
    }, [statData, statGridRef, statKeyExpr, statOnRowDblClick, isXSmall, isSmall]);

    const chrgGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'충전기'}
                role={'목록'}
                gridDataSource={chrgData}
                gridRef={chrgGridRef}
                mainKey={chrgKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowClick={() => console.log("ok")}
                onRowDblClick={chrgOnRowDblClick}
                selection={'single'}
                toolbar
                search
            >
                {CommCol({ dataField: "station_name", caption: "충전소명" })}
                {CommCol({ dataField: "id", caption: "충전기ID" })}
                {CommCol({ dataField: "model", caption: "충전기모델" })}             
            </ScrollGrid>
        )
    }, [chrgData, chrgGridRef, chrgKeyExpr, chrgOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'충전기 조회'}
                visible={chrgPopupVisible}
                shown={chrgPopupOnShowing}
                hiding={chrgPopupHiding}
            >
                {chrgGrid}
            </PopupGrid>     

            <PopupGrid
                title={'충전소 조회'}
                visible={statPopupVisible}
                shown={statPopupOnShowing}
                hiding={statPopupHiding}
            >
                {statGrid}
            </PopupGrid>
            <PopupForm
                title={'A/S이력 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 800}
            >
                <GroupItem colCount={isXSmall || isSmall ? 1 : 2}>
                    {FormText({ value: data.aser_numb, onValueChange: updateField('aser_numb'), label: "A/S접수번호", disabled: true })}
                    {FormSelc({ value: data.aser_gubu, onValueChange: updateField('aser_gubu'), label: "진행상황", items: gubuItem })}
                    {FormGrid({ value: data.stin_name, onValueChange: updateField('stin_name'), label: "충전소명", required: true, onClick: () => setStatPopupVisible(true) })}
                    {FormText({ value: data.stin_idno, onValueChange: updateField('stin_idno'), label: "충전소ID", required: true, disabled: true })}
                    {FormGrid({ value: data.cpin_idno, onValueChange: updateField('cpin_idno'), label: "충전기ID", required: true, onClick: onChrgPopupClick })}
                    {FormText({ value: data.cpin_modl, onValueChange: updateField('cpin_modl'), label: "충전기모델명", required: true, disabled: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "신청고객명" })}
                    {FormText({ value: data.clie_teln, onValueChange: updateField('clie_teln'), label: "신청고객연락처" })}
                    {FormText({ value: data.acce_name, onValueChange: updateField('acce_name'), label: "접수자" })}
                    {FormDate({ value: data.acce_date, onValueChange: updateField('acce_date'), label: "접수일" })}
                    {FormText({ value: data.comp_name, onValueChange: updateField('comp_name'), label: "담당자" })}
                    {FormDate({ value: data.comp_date, onValueChange: updateField('comp_date'), label: "완료일" })}
                </GroupItem>
                {FormMemo({ value: data.acce_memo, onValueChange: updateField('acce_memo'), label: "접수증상" })}
                {FormMemo({ value: data.comp_memo, onValueChange: updateField('comp_memo'), label: "조치내용" })}
                {FormMemo({ value: data.aser_memo, onValueChange: updateField('aser_memo'), label: "특이사항" })}
            </PopupForm>
        </>
    );
}