import { fwUtil } from "../../focuswin/script/util";
import routerInfo from "../../utils/router-info";

const getVocsStatList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/bor/voc/regist/station/list`;
        const data = await fwUtil.apis.search(api, sendData);

        return data;
    }
    catch (error) {
        console.log('getVocsStatList.error === ', error);
        return [];
    }
};

const getVocsChrgList = async (sendData) => {
    try {
        const api = `${routerInfo.manaRout}/set/bor/voc/regist/charger/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getVocsChrgList.error === ', error);
        return [];
    }
};

export {
    getVocsStatList,
    getVocsChrgList,
}