import React, { useState } from "react";
import { Button, List } from "devextreme-react";
import { SearchEditorOptions } from "devextreme-react/cjs/list";
import { ReactComponent as ChargerIcon } from "../../../../assets/svg/charger.svg";
import { ReactComponent as StationIcon } from "../../../../assets/svg/station2.svg";
import mapUtil from "../../../../script/components/map/map-util";
import { fwUtil } from "../../../../script/util";
import './MoniMaps-panel.scss';

export function MoniMapsPanel (props) {
    const {
        stationData,
        chargerData,
        chargerSearch,
        searchExpr, map,
        setLoading
    } = props;

    const [overlayActive, setOverlayActive] = useState(true);    
    const [detailActive, setDetailActive] = useState(false);    
    const listAttrs = { class: 'fw-maps-overlay-list' };
    const buttonText = overlayActive ? "<" : ">";
    
    async function stationListClick(item) {
        setLoading(true);
        const allStMarker = document.querySelectorAll('.fw-map-overlay-content.station');
        allStMarker.forEach(marker => {
            marker.classList.remove('sel');
        });        
        await chargerSearch(item.station_id, setDetailActive);
        mapUtil.navigate.addr(map, item.station_address);

        mapUtil.navigate.addr(map, item.station_address, () => {
            const selectedStMarker = document.getElementById(`station-${item.station_id}`);
            if (selectedStMarker) {
                selectedStMarker.classList.add('sel');
            }
        });
    };

    async function onChargerListClick(chargerItem) {
        const allChMarker = document.querySelectorAll('.fw-map-overlay-content.charger');
        const pSelectedStMarker = document.getElementById(`charger-${chargerItem.station_id}-${chargerItem.id}`);

        allChMarker.forEach(marker => {
            if (marker !== pSelectedStMarker) {
                marker.classList.remove('sel');
            }
        });
        await mapUtil.navigate.posi(map, chargerItem.gps_xpos, chargerItem.gps_ypos);  
        
        const selectedStMarker = document.getElementById(`charger-${chargerItem.station_id}-${chargerItem.id}`);

        if (selectedStMarker) {
            selectedStMarker.classList.add('sel');
        }
    }

    const StationList = (item) => {
        return(
        <div className="fw-maps-overlay-list-item-container">
            <div className="fw-maps-overlay-list-item" onClick={() => stationListClick(item) }>
                <div className={`fw-list-item-header`}>
                    <div id={`fw-list-item-${item.station_id}`} className={`fw-list-item-main-img ${item.conn_stat}`}><StationIcon /></div>       
                    <span title={item.station_name}>{item.station_name}</span>             
                </div>
                <div className={`fw-list-item-detail ${item.conn_stat}`}>
                    <div className="fw-list-item-address fw-list-item-detail-list" title={item.station_address}>
                        <div><i className="dx-icon-home"></i></div>
                        <span>{item.station_address}</span>
                    </div>
                </div>
            </div>
        </div>
    )};

    return (
        <>
        <div className={`fw-maps-overlay-list-container  ${overlayActive? 'active' : 'passive'}`}>
            <Button
                className="fw-maps-overlay-toggle-button"
                text={buttonText}
                visible={!detailActive}
                onClick={() => { setOverlayActive(!overlayActive) }}
            />
            <List
                selectionMode="single"
                dataSource={stationData}
                searchEnabled
                searchExpr={searchExpr}
                searchMode="contains"
                itemRender={StationList}
                elementAttr={listAttrs}
            >
                <SearchEditorOptions
                    stylingMode="outlined"
                    placeholder="충전소 검색 (이름 또는 주소)"
                    showClearButton={false}
                />
            </List>
        </div>                
        <div className={`fw-maps-overlay-detail-container ${detailActive? 'active' : 'passive'}`}>                
            <Button
                text="<"
                stylingMode="contained"
                className="fw-maps-overlay-detail-close-button"
                onClick={() => setDetailActive(!detailActive)}
            />            
            <div className="fw-maps-overlay-detail">
            {chargerData && chargerData.length > 0 ? (
                chargerData.map((chargerItem, index) => (
                    <div key={index} className="fw-maps-charger-list">
                        <div className={`fw-maps-charger-list-item ${chargerItem.state}`}>
                            <div className="fw-maps-charger-header"><ChargerIcon /></div>                                
                            <div className="fw-maps-charger-detail">
                                <div className="fw-maps-charger-name">{chargerItem.char_name}</div>
                                <div className="fw-maps-charger-state">{`● ${fwUtil.conv.to.krChrgStat(chargerItem.state)}`}</div>                                
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="fw-maps-overlay-detail-none-mes">
                    충전기 데이터를 찾을 수 없습니다.
                </div>
            )}
            </div>                
        </div>        
        </>
    )
};