import { Routes, Route, Navigate } from 'react-router-dom';
import { AccountPanel, GubunForm, LogiAForm, LogiUForm } from './focuswin/script/components';
import { intrComp, suppComp } from './utils/default-comp';

export default function AuthContent() {
  return (
    <Routes>
      <Route
        path='/gubun' 
        element={
          <AccountPanel title="로 그 인" detail="로그인 할 계정 유형을 선택해주세요." intrComp={intrComp} suppComp={suppComp} >
            <GubunForm />
          </AccountPanel>
        }
      />
      <Route
        path='/admin/login' 
        element={
          <AccountPanel title="로 그 인" detail="관리자 ID와 비밀번호를 입력하여 주세요." intrComp={intrComp} suppComp={suppComp} >
            <LogiAForm />
          </AccountPanel>
        }
      />      
      <Route
        path='/user/login' 
        element={
          <AccountPanel title="로 그 인" detail="사용자 ID와 비밀번호를 입력하여 주세요." intrComp={intrComp} suppComp={suppComp} >
            <LogiUForm />
          </AccountPanel>
        }
      />        
      <Route path='*' element={<Navigate to={'/gubun'} />}></Route>
    </Routes>
  );
}
