// ChrgInfoMana - 충전기 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { getCharPageStat } from '../../../../../api/cms/set-chrg';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { ChrgInfoManaInsert } from './InfoMana-insert';
import { ChrgInfoManaUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const chrgTypeCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value == 0) {
        displayText = '완속형';
        color = '#4CAF50';
    } else if (value == 1) {
        displayText = '급속형';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};
export default function ChrgInfoMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const mainMiddleApi = 'charger/register';
    const mainSearchApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'search');
    const mainInsertApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'insert');
    const mainUpdateApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['id', 'station_id', 'connector_id'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    // 검색 조건
    const [stationInfo, setStationInfo] = useState('');
    const [model, setModel] = useState("");
    const [stationList, setStationList] = useState([]);
    const stationInfoChange = useCallback((e) => { setStationInfo(e) }, []);
    const modelChange = useCallback((e) => { setModel(e) }, []);
    const stationListChange = useCallback((e) => { setStationList(e) }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    const fetchSet = useCallback(async () => {
        try {
            const [stationListResult] = await Promise.all([getCharPageStat()]);
            stationListChange(stationListResult);
            stationInfoChange(stationListResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.nsWarn();;
        }
    }, [stationInfoChange, stationListChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { stationName: stationInfo.station_name === "전체" ? "" : stationInfo.station_name, model: model };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef);
    }, [stationInfo, model, mainGridDataUpdate, mainGridRef, mainSearchApi]);

    useEffect(() => {
        fetchSet();
    }, [fetchSet]);

    useEffect(() => {
        stationInfo && mainHandleSearch();
    }, [mainHandleSearch, stationInfo])

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '충전소', value: stationInfo, valueChange: stationInfoChange, search: mainHandleSearch, items: stationList, displayExpr: 'station_name', width: 170 },
        { type: 'text', locate: 'before', caption: '모델명', value: model, valueChange: modelChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [stationInfo, stationInfoChange, stationList, model, modelChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('관리코드를 선택 후 시도해주세요.');
            return;
        }

        if (type === 'main') {
            handleMainAction();
        }

    }, [mainGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-chrg'} title={title} />
            <div id={'fw-page-content'}>
                <MainGrid
                    title={'충전기 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={mainGridRef}
                    gridDataSource={mainGridData}
                    ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "id", caption: "충전기ID", width: 160 })}
                    {CommCol({ dataField: "model", caption: "모델명", width: 140 })}
                    {CommCol({ dataField: "clie_name", caption: "사업자명", width: 100 })}
                    {CommCol({ dataField: "station_name", caption: "충전소명", width: 160 })}
                    {CommCol({ dataField: "char_enid", caption: "환경부용ID", width: 120 })}
                    {CommCol({ dataField: "gps_xpos", caption: "위도", width: 140 })}
                    {CommCol({ dataField: "gps_ypos", caption: "경도", width: 140 })}
                    {CommCol({ dataField: "char_sped", caption: "충전기 속도", width: 140 })}
                    {StatCol({ dataField: "charger_type", caption: "충전기 유형", width: 140, customCell: chrgTypeCell })}
                    {CommCol({ dataField: "char_publ", caption: "공용구분", width: 100 })}
                    {CommCol({ dataField: "char_con1", caption: "커넥터타입1", width: 120 })}
                    {CommCol({ dataField: "char_con2", caption: "커넥터타입2", width: 120 })}
                    {DateCol({ dataField: "char_init", caption: "설치일", width: 100 })}
                    {DateCol({ dataField: "char_date", caption: "운영시작일", width: 100 })}
                    {BoolCol({ dataField: "char_enab", caption: "운영여부", width: 100 })}
                    {BoolCol({ dataField: "char_envs", caption: "환경부 로밍여부", width: 100 })}
                    {CommCol({ dataField: "char_prot", caption: "프로토콜", width: 100 })}
                    {CommCol({ dataField: "char_addr", caption: "Anydesk ID", width: 120 })}
                    {CommCol({ dataField: "char_mode", caption: "시리얼 번호", width: 120 })}
                    {CommCol({ dataField: "char_teln", caption: "전화번호", width: 100 })}
                </MainGrid>
                <ChrgInfoManaInsert
                    refresh={mainHandleSearch}
                    insertApi={mainInsertApi}
                    visible={mainInsertPopupVisible}
                    hiding={mainInsertPopupHide}
                />
                <ChrgInfoManaUpdate
                    refresh={mainHandleSearch}
                    mainGrid={mainGridRef}
                    updateApi={mainUpdateApi}
                    visible={mainUpdatePopupVisible}
                    hiding={mainUpdatePopupHide}
                />
            </div>
        </>
    );
};