import React from 'react';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';

const aserStateCell = (cellInfo) => {
    const value = cellInfo.value;

    const colorMap = {
        '접수': '#5bc0de',
        '안내': '#f0ad4e',
        '예정': '#5bc0de',
        '진행중': '#0275d8',
        '완료': '#5cb85c'
    };

    const color = colorMap[value] || '#6c757d';

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>
                ● {value || "알수없음"}
            </span>
        </div>
    );
};

// 그룹 렌더링 함수
const stationGroupRender = (data) => {
    if (!data || data.length < 1) {
        return null;
    }

    const cntColumn = data.totalItem?.data?.items?.length || 0;

    return (
        <div className='fw-custom-gruop-moni-dash'>
            <div className={`fw-group-title ${data.displayValue === '미해결' ? 'erro' : ''}`}>
                {data.displayValue || '알수없음'}
            </div>
            <div className='fw-group-detail'>
                총 <b>{cntColumn}</b> 건
            </div>            
        </div>
    );
};

const MoniDashDaysGrid = React.memo((props) => {
    const {
        gridData, gridRef, gridHeight,
        mainTitle, mainRole, additonTitle
    } = props;

    // 그리드
    const mainKey = 'aser_numb';

    return (
        <ScrollGrid
            title={mainTitle}
            role={mainRole}
            additonTitle={additonTitle}
            height={gridHeight}
            mainKey={mainKey}
            gridRef={gridRef}
            gridDataSource={gridData}
            loadPanel={false}
            toolbar={{ titleOnly: true }}
            selection={'none'}
        >
            {CommCol({ dataField: "aser_type", caption: "상태", groupIndex: 0, groupRender: stationGroupRender })}
            {CommCol({ dataField: "stin_name", caption: "충전소명" })}
            {CommCol({ dataField: "cpin_idno", caption: "충전기ID" })}            
            {StatCol({ dataField: "aser_gubu", caption: "진행상황", customCell: aserStateCell })}
            {CommCol({ dataField: "cpin_modl", caption: "충전기모델명" })}
            {CommCol({ dataField: "clie_name", caption: "신청고객명" })}
            {CommCol({ dataField: "clie_teln", caption: "신청고객연락처" })}
            {DateCol({ dataField: "acce_date", caption: "접수일" })}
            {CommCol({ dataField: "acce_name", caption: "접수자" })}
            {CommCol({ dataField: "acce_memo", caption: "접수증상" })}
            {CommCol({ dataField: "comp_name", caption: "담당자" })}
            {DateCol({ dataField: "comp_date", caption: "완료일" })}
            {CommCol({ dataField: "comp_memo", caption: "조치내용" })}
            {CommCol({ dataField: "aser_memo", caption: "특이사항" })}         
        </ScrollGrid>
    );
});

export default MoniDashDaysGrid;
