import React, { useState, useCallback } from 'react';
import Form from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../../../contexts/auth';
import { FormPass, FormText } from '../form/form-items';
import { Button, LoadIndicator, ValidationGroup } from 'devextreme-react';
import { Link } from 'react-router-dom';

const defaultData = {
  user_idno: '',
  pass_word: '',
};

export default function FormLogiA() {
  // 시스템
  const { signInAdmin } = useAuth();
  // 페이지 상태값
  const [loading, setLoading] = useState(false);
  // 데이터
  const [data, setData] = useState(defaultData);
  const dataUpdate = useCallback((e) => setData(e), [setData]);

  // 로그인
  const onSubmit = useCallback(async () => {

    const postData = {
      userIdno: data.user_idno,
      passWord: data.pass_word,
    }
    setLoading(true);

    const result = await signInAdmin(postData.userIdno, postData.passWord);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signInAdmin, data]);

  // 로그인 버튼 클릭
  const handleLogin = useCallback((e) => {
    e.preventDefault();
    onSubmit();
  }, [onSubmit]);

  // 수정
  const updateField = (field) => (value) => {
    if (!data) return;

    dataUpdate({ ...data, [field]: value });
  };

  return (
    <div id={'login-form'} className={'login-form'}>
      <ValidationGroup>
        <form onSubmit={handleLogin}>
          <Form className='account-panel-form'>
            {FormText({ value: data.user_idno, onValueChange: updateField('user_idno'), label: '아이디', required: true, hideMarker: true, placeholder: '아이디' })}
            {FormPass({ value: data.pass_word, onValueChange: updateField('pass_word'), label: '비밀번호', required: true, hideMarker: true, placeholder: '비밀번호' })}
          </Form>
          <Button
            width={'100%'}
            type='default'
            useSubmitBehavior={true}
            disabled={loading}
            className='first-btn'
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : '로그인'
              }
            </span>
          </Button>
        </form>
      </ValidationGroup>
      <div className={'link'}>
        <Link to={'/user/login'}>사용자 로그인</Link>
      </div>          
    </div>
  );
};