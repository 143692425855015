// MoniDashStat - 모니터링 - 대쉬보드
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { ContentHeader } from '../../../../script/components/header/Header';
import { LoadOverlay } from '../../../../script/components/load-overlay/load-overlay';
import { moniDashFunc } from './MoniDash-util';
import { fwUtil } from '../../../../script/util';
import MoniDashDaysGrid from './MoniDash-days';
import MoniDashChrgGrid from './MoniDash-chrg';
import MoniDashRptsGrid from './MoniDash-rpts-grid';
import MoniDashRptsChar from './MoniDash-rpts-char';

const gridMargin = 30;
const gridHeaderHeight = 43;
const gridHeaderColHeight = 40.5;
const gridPagerHeight = 20;


export default function MoniDashStat(props) {
    const {
        acce, cate, code,
        mainTitle, 
    } = props;

    // api
    const daysMiddleApi = 'dashboard/status/a';
    const chrgMiddleApi = 'dashboard/status/b';
    const rptgMiddleApi = 'dashboard/status/c';
    const daysSearchApi = fwUtil.apis.create(acce, cate, daysMiddleApi, 'search');
    const chrgSearchApi = fwUtil.apis.create(acce, cate, chrgMiddleApi, 'search');
    const rptgSearchApi = fwUtil.apis.create(acce, cate, rptgMiddleApi, 'search');
    // 시스템
    const { isLarge } = useScreenSize(); 
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 헤더
    const daysTitle = "A/S";
    const chrgTitle = "충전기별";
    const rptsTitle = "일자별";
    const daysRole = "현황";
    const chrgRole = "현황";
    const rptsRole = "충전현황 (최근 7일 기준)";
    const chrgAdditonTitle = `(${fwUtil.date.getsText.month()} 누계)`;
    const rptsAdditonTitle = `(${fwUtil.date.getsText.pDate(13)} - ${fwUtil.date.getsText.date()} 2주간)`;    
    // 권한
    const { authCheck } = useAuth();
    // 데이터
    const [daysData, setDaysData] = useState([]);
    const [chrgData, setChrgData] = useState([]);
    const [rptgData, setRptgData] = useState([]);
    const [rptcData, setRptcData] = useState([]);
    const daysDataUpdate = useCallback((e) => setDaysData(e), []);  
    const chrgDataUpdate = useCallback((e) => setChrgData(e), []);  
    const rptgDataUpdate = useCallback((e) => setRptgData(e), []);
    const rptcDataUpdate = useCallback((e) => setRptcData(e), []);
    const daysGridRef = useRef(null);
    const chrgGridRef = useRef(null);
    const rptsGridRef = useRef(null);
    const rptsCharRef = useRef(null); 
    // 높이
    const [unitHeight, setUnitHeight] = useState(1);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, null, code);
    }, [authCheck, setLoading, code]);       

    // 조회
    const handleSearch = useCallback(async () => {
        if (!loading) {
            setPageLoading(true);
            try {
                const sendAserDate = fwUtil.date.getsText.range();
                const sendAserData = { start: sendAserDate[0], compl: sendAserDate[1], duraYesn: true }
                const daysRes = await fwUtil.apis.search(daysSearchApi, sendAserData);
                
                const chrgRes = await fwUtil.apis.search(chrgSearchApi);
    
                const sendRptgData = { start: fwUtil.date.getsText.pDate(6), compl: fwUtil.date.getsText.date() }
                const rptgRes = await fwUtil.apis.search(rptgSearchApi, sendRptgData);
                const rptcRes = moniDashFunc.transRptsData(rptgRes, 6)
                
                rptcDataUpdate(rptcRes);
                rptgDataUpdate(rptgRes);
                daysDataUpdate(daysRes);
                chrgDataUpdate(chrgRes);                
                setPageLoading(false);
            } catch (error) {
                console.error('Data fetching error:', error);
                setPageLoading(false);
            }
        }
    }, [loading, daysSearchApi, chrgSearchApi, rptgSearchApi, daysDataUpdate, chrgDataUpdate, rptgDataUpdate, rptcDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    useEffect(() => {
        const viewportHeight = window.innerHeight > 960 ? window.innerHeight - 15 : 945;
        const contentHeight = viewportHeight - gridMargin;
        let gridHeight;

        gridHeight = contentHeight / 2 - gridHeaderHeight - gridHeaderColHeight - gridPagerHeight;
        setUnitHeight(gridHeight);
    }, [setUnitHeight]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
        <LoadOverlay visible={pageLoading} />
        <ContentHeader icon={'fw-moni'} title={mainTitle} refresh={handleSearch} />
        <div id={'fw-page-content'} className={`fw-${isLarge? 'row' : 'column'}-flex-box accent-page`}>
            <div className={`fw-half-content fw-column-flex-box ${isLarge? 'half' : 'full'}`}>
                <MoniDashChrgGrid gridData={chrgData} girdRef={chrgGridRef} mainTitle={chrgTitle} mainRole={chrgRole} additonTitle={chrgAdditonTitle} gridHeight={unitHeight}/>
                <MoniDashDaysGrid gridData={daysData} girdRef={daysGridRef} mainTitle={daysTitle} mainRole={daysRole} gridHeight={unitHeight} />
            </div>
            <div className={`fw-half-content fw-column-flex-box ${isLarge? 'half' : 'full'}`}>
                <MoniDashRptsGrid gridData={rptgData} gridRef={rptsGridRef} mainTitle={rptsTitle} mainRole={rptsRole} gridHeight={unitHeight} />
                <MoniDashRptsChar charData={rptcData} charRef={rptsCharRef} mainTitle={rptsTitle} mainRole={rptsRole} additonTitle={rptsAdditonTitle} charHeight={unitHeight} />
            </div>            
        </div>        
        </>
    );
};