import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'devextreme-react';

export default function FormGubun() {
  const navigate = useNavigate();

  return (
    <div id={'login-form'} className={'login-form'}>
      <Button
        width={'100%'}
        type='normal'
        onClick={() => navigate('/admin/login')}
        className='first-btn'
        text='관리자 로그인'
      />
      <Button
        width={'100%'}
        type='default'
        onClick={() => navigate('/user/login')}
        className='account-btn'
        text='사용자 로그인'
      />      
    </div>
  );
}
