// BoarVocsMana - 게시판관리 - 고장신고 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { BparVocsInsert } from './InfoMana-insert';
import { BparVocsUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const statDeleCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "N" ? '미삭제' : '삭제';
    const color = value === "N" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

const statChecCell = (cellInfo) => {
    const value = cellInfo.value;
    const texts = value === "Y" ? '확인' : '미확인';
    const color = value === "Y" ? '#56a0eb' : '#ff7777';

    return (
        <div className={'fw-state-column'}>
            <span className={`fw-state-text`} style={{ color: color }}>● {texts}</span>
        </div>
    )
};

export default function BoarVocsMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'voc/register';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'vocId';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [stationName, setStationName] = useState("");
    const [customerName, setCustomerName] = useState("");
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);
    const customerNameChange = useCallback((e) => { setCustomerName(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { stationName: stationName, customerName: customerName };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef);
    }, [stationName, customerName, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '서비스 회사명', value: stationName, valueChange: stationNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '제목', value: customerName, valueChange: customerNameChange, search: handleSearch },    
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stationName, stationNameChange, customerName, customerNameChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-boar'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'고장신고 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명" })}
                    {CommCol({ dataField: "cpId", caption: "충전기ID" })}
                    {CommCol({ dataField: "connectorId", caption: "커넥터ID" })}
                    {CommCol({ dataField: "custId", caption: "고객ID" })}
                    {CommCol({ dataField: "customerName", caption: "고객명" })}
                    {CommCol({ dataField: "vocGubun", caption: "구분" })}
                    {CommCol({ dataField: "vocTitle", caption: "제목" })}
                    {CommCol({ dataField: "vocContents", caption: "문의내용" })}
                    {CommCol({ dataField: "vocActnContents", caption: "답변내용" })}
                    {DateCol({ dataField: "vocCmpltDt", caption: "처리완료일" })}
                    {StatCol({ dataField: "delYn", caption: "삭제여부", customCell: statDeleCell })}
                    {StatCol({ dataField: "checkYn", caption: "확인여부", customCell: statChecCell })}
                    {CommCol({ dataField: "reguserId", caption: "등록자" })}
                    {DateCol({ dataField: "regDt", caption: "등록일" })}
                    {CommCol({ dataField: "modUserId", caption: "수정자" })}
                    {DateCol({ dataField: "modDt", caption: "수정일" })}
                </PagingGrid>
                <BparVocsInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <BparVocsUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />               
            </div>
        </>
    );
};